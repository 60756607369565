import React, { useEffect, useState } from 'react';
import './style.scss';
import { Dropdown } from 'react-bootstrap';
import CTAButtons from '../CTAButtons';

const TeamAppreciation = ({ value }) => {
    const [employeeCount, setEmployeeCount] = useState(value ? value : 0);
    const [percentage, setPercentage] = useState({
        percentage: 20,
        amount: parseFloat(value ? value : 0.2 * 3000 * 0.303).toFixed(0),
    });
    const tempIndustry = [
        {
            industry: 'Tech',
            isActive: true,
            percentage: 20,
        },
        {
            industry: 'Manufacturing',
            isActive: false,
            percentage: 16,
        },
        {
            industry: 'Finance',
            isActive: false,
            percentage: 15,
        },
        {
            industry: 'Others',
            isActive: false,
            percentage: 17.7,
        },
    ];
    const [industry, setIndustry] = useState(tempIndustry);

    const handleInputChange = (value) => {
        if ((value >= 0 || value === '') && value < 2001 && !value.includes('.')) {
            setEmployeeCount(value);
        }
    };

    const handleIndustry = (index) => {
        let temp = [...industry];
        let tempPercentage = JSON.parse(JSON.stringify(percentage));
        temp.forEach((industry, i) => (i === index ? (industry.isActive = true) : (industry.isActive = false)));
        setIndustry(temp);
        tempPercentage.percentage = temp[index].percentage;
        tempPercentage.amount = parseFloat(
            (employeeCount * (temp[index].percentage / 100) * 3000 * 0.303).toString()
        ).toFixed(0);
        setPercentage(tempPercentage);
    };

    useEffect(() => {
        let tempPercentage = JSON.parse(JSON.stringify(percentage));
        tempPercentage.amount = parseFloat(
            (employeeCount * (tempPercentage.percentage / 100) * 3000 * 0.303).toString()
        ).toFixed(0);
        setPercentage(tempPercentage);
    }, [employeeCount, percentage]);

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    return (
        <div className='teamAppreciationCostWrap'>
            <div className='engageContainer'>
                <div className='teamACHeader'>
                    <h3>How much can EngageWith help you save?</h3>
                </div>
                <div className='teamACWrap'>
                    <div className='teamACSection'>
                        <div className='teamACSectionHeader'>
                            <h3>Your team</h3>
                        </div>
                        <div className='teamACSectionContent'>
                            <h5>Employees</h5>
                            <div className='teamACSContentWrap'>
                                <div className='teamACSContent'>
                                    <input
                                        value={employeeCount}
                                        type='text'
                                        onChange={(e) => handleInputChange(e.target.value)}
                                    />
                                    <div className='slidecontainer'>
                                        <div className='sliderViewer'>
                                            <input
                                                value={employeeCount === '' ? 0 : employeeCount}
                                                onChange={(e) => handleInputChange(e.target.value)}
                                                min='0'
                                                max='2000'
                                                type='range'
                                                className='slider p-0'
                                                id='myRange'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h5>Industry</h5>

                            <div className='employeeIndustry employeeIndustryMob'>
                                <div className='employeeIndustryInner'>
                                    {industry.map((industry, i) => (
                                        <span
                                            role={'button'}
                                            tabIndex={'0'}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) handleIndustry(i);
                                            }}
                                            onClick={() => handleIndustry(i)}
                                            className={industry.isActive ? `industryActive` : ''}
                                        >
                                            {industry.industry}
                                        </span>
                                    ))}
                                </div>
                            </div>

                            <Dropdown className='teamACSDropDown'>
                                <Dropdown.Toggle id='dropdown-basic'>
                                    <span>
                                        {industry.map((industry) => (industry.isActive ? industry.industry : null))}
                                    </span>
                                    <img
                                        src={'https://sw-assets.gumlet.io/engagewith/arrow-blue.svg'}
                                        data-src={'https://sw-assets.gumlet.io/engagewith/arrow-blue.svg'}
                                        alt={''}
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {industry.map((industry, i) =>
                                        !industry.isActive ? (
                                            <Dropdown.Item onClick={() => handleIndustry(i)}>
                                                {industry.industry}
                                            </Dropdown.Item>
                                        ) : null
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>

                    <div className='teamACSection teamACSectionSaving'>
                        <div className='teamACSectionHeader d-flex justify-content-between'>
                            <h3>Your savings</h3>
                            <button onClick={() => window.open('https://calendly.com/engagewith-demo/demo', '_blank')}>
                                Book Free Demo
                            </button>
                        </div>

                        <div className='teamACCalculation'>
                            <div className='teamACCalculationRow'>
                                <div className='teamACCalcLeft'>
                                    <p>
                                        People who'd leave due to the lack of appreciation <span>?</span>
                                        <div className='teamACToolTip'>
                                            <p>
                                                Among your employees, the number of people who'd leave
                                                <a
                                                    href='https://www.e-days.com/news/employee-turnover-rates-an-industry-comparison'
                                                    target='_blank'
                                                    rel='noreferrer noopener'
                                                >
                                                    {' '}
                                                    due to lack of appreciation{' '}
                                                </a>
                                            </p>
                                        </div>
                                    </p>
                                </div>

                                <div className='teamACCalcRight'>
                                    <h5>
                                        {employeeCount > 1
                                            ? numberWithCommas(
                                                  parseFloat(
                                                      (employeeCount * (percentage.percentage / 100)).toString()
                                                  ).toFixed(0)
                                              )
                                            : 0}
                                    </h5>
                                </div>
                            </div>
                            <div className='teamACCalculationRow'>
                                <div className='teamACCalcLeft'>
                                    <p>
                                        Total cost of hiring and training a new hire <span>?</span>
                                        <div className='teamACToolTip'>
                                            <p>
                                                The average cost of{' '}
                                                <a
                                                    href='https://yello.co/blog/recruitment-operations-statistics/#:~:text=Cost%2Dper%2DHire%20Trends,more%20than%20%246%2C000%20per%20opening.'
                                                    target='_blank'
                                                    rel='noreferrer noopener'
                                                >
                                                    hiring and training a new employee{' '}
                                                </a>
                                            </p>
                                        </div>
                                    </p>
                                </div>
                                <div className='teamACCalcRight'>
                                    <h5>
                                        $
                                        {employeeCount > 1
                                            ? numberWithCommas(
                                                  parseFloat(
                                                      (employeeCount * (percentage.percentage / 100)).toString()
                                                  ).toFixed(0) * 3000
                                              )
                                            : 0}
                                    </h5>
                                </div>
                            </div>

                            <div className='teamACCalcTotal'>
                                <h4>${employeeCount > 1 ? numberWithCommas(percentage.amount) : 0}</h4>
                                <p className='mr-0'>
                                    EngageWith saves per year <span>?</span>
                                    <div className='teamACToolTip'>
                                        <p>
                                            Recognition can{' '}
                                            <a
                                                href='https://www.inc.com/ryan-jenkins/how-to-elevate-millennial-performance-using-recognition.html'
                                                target='_blank'
                                                rel='noreferrer noopener'
                                            >
                                                reduce employee turnover
                                            </a>{' '}
                                            rates drastically
                                        </p>
                                    </div>
                                </p>
                            </div>
                        </div>

                        <div className='installEngaeWith'>
                            <h6>Install EngageWith for your team</h6>
                            <CTAButtons />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamAppreciation;
