import React from 'react';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import Navbar from '../../../components/engagewith/Navbar';
import Footer from '../../../components/engagewith/Footer';
import TeamAppreciationCost from '../../../components/engagewith/TeamAppreciationCost';
import './style.scss';

const Index = ({ location }) => {
    const value = queryString.parse(location.search);
    return (
        <div>
            <Helmet>
                <title>Employee Recognition in Slack & Microsoft Teams | EngageWith</title>
                <meta property='og:title' content='Employee Recognition in Slack & Microsoft Teams | EngageWith' />
                <meta property='og:site_name' content='EngageWith' />
                <meta property='og:url' content='https://www.springworks.in/engagewith/' />
                <meta
                    property='og:description'
                    content='EngageWith is a rewards and recognition platform inside Slack & Microsoft Teams that increases employee retention and builds a culture of recognition.'
                />
                <meta
                    name='description'
                    content='EngageWith is a rewards and recognition platform inside Slack & Microsoft Teams that increases employee retention and builds a culture of recognition.'
                />
                <meta property='og:type' content='website' />
                <meta
                    property='og:image'
                    content='https://assets-springengage.s3.amazonaws.com/web/engage_header_logo.png'
                />
                <link rel='canonical' href='https://www.springworks.in/engagewith/' />
                <meta name='twitter:card' content='summary' />
                <meta name='twitter:site' content='@springroleinc' />
                <meta name='twitter:title' content='Employee Recognition in Slack & Microsoft Teams | EngageWith' />
                <meta
                    name='twitter:description'
                    content='EngageWith is a rewards and recognition platform inside Slack & Microsoft Teams that increases employee retention and builds a culture of recognition.'
                />
                <meta
                    name='twitter:image'
                    content='https://assets-springengage.s3.amazonaws.com/web/engage_header_logo.png'
                />
                <meta property='og:image:width' content='1200' />
                <meta property='og:image:height' content='600' />
                <script type='application/ld+json'>
                    {`{
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "EngageWith",
                        "url": "https://www.springworks.in/engagewith/",
                        "logo": "https://d1xyh4hv0scrr0.cloudfront.net/web/engage_logo.jpg",
                        "sameAs": [
                          "https://www.facebook.com/springrole",
                          "https://twitter.com/springroleinc/",
                          "https://www.linkedin.com/organization-guest/company/springrole/",
                          "https://www.youtube.com/channel/UC7jYeZWJHhsV_2266gVMsZw"
                        ]
                    }`}
                </script>
            </Helmet>
            <div className='engageNavbarContainer'>
                <div className='engageContainer'>
                    <Navbar />
                </div>
            </div>
            <TeamAppreciationCost value={value?.value > 1 ? value.value : 100} />
            <Footer />
        </div>
    );
};

export default Index;
